import React from 'react';
import Header from './Header';
import './Home.css'; // Uncomment this line to use the CSS

const Home = () => {
  const handleCallButtonClick = () => {
    window.location.href = 'tel:+5412418998'; // Replace +1234567890 with your phone number
  };


  return (
    <div>
      <Header /> {/* This includes the navigation and any other header content */}
      <div className="home-content">{/* Wrapper for the main content of the home page */}

            <h1>Nate Loker </h1>
            <h2> Columbia River Gorge | Real Broker LLC </h2>
            <h3> List your home for less commission.</h3>
            <ul> 
            <li> I work independently so I can offer you the best possible rates. </li>

            </ul>

            <img src="head_shot.jpg" alt="Nate Loker" className="headshot" />
            <h2>Contact Info </h2>
            <ul>
            <li>Cell: (541) 241-8998 </li>
            <li>Email: nloker1@gmail.com </li>

            </ul>


            <button className="call-button" onClick={handleCallButtonClick}>Call Now</button>


            <img src="gorge_photo2.jpg" className="gorge_photo"/>

      </div>

    </div>
  );
}

export default Home;
